<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-15 08:53:40
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-15 15:26:13
-->
<template>
  <el-dialog :visible.sync="visible" :close-on-click-modal="false" :title="title" width="400px">
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="100px"
      @keyup.enter.native="submitHandle()">
      <el-form-item label="所属分组" prop="group">
        <el-select class="main-select-tree" ref="selectTree" v-model="dataForm.group" style="width:100%;">
          <el-option
            v-for="item in groupLists"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            style="display: none"/>
            <el-tree
              class="main-select-el-tree"
              ref="selecteltree"
              :data="treeData"
              node-key="id"
              highlight-current
              :props="defaultProps"
              @node-click="_handleNodeClick"
              default-expand-all/>
        </el-select>
      </el-form-item>
      <el-form-item label="题库名称" prop="questionBankName">
        <el-input v-model="dataForm.questionBankName" placeholder="请输入题库名称" clearable></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submitHandle()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    treeData: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      optionType: '',
      topicItem: {},
      visible: false,
      groupLists: [{}],
      title: '',
      dataForm: {
        group: '', // 分组
        parentId: '', // 分组id
        questionBankGroupId: '', // 分组id
        questionBankName: '' // 题库名称
      },
      dataRule: {
        group: [{ required: true, message: '请选择所属分组', trigger: ['blur', 'change'] }],
        questionBankName: [{ required: true, message: '请选择所属分组', trigger: ['blur', 'change'] }]
      }
    }
  },
  methods: {
    init (type, row) {
      this.optionType = type
      this.topicItem = row
      this.visible = true
      this.title = type === 'add' ? '新增题库' : '编辑题库'
      if (type === 'edit') {
        this.dataForm.group = row.questionBankGroupName
        this.dataForm.questionBankGroupId = row.questionBankGroupId
        this.dataForm.questionBankName = row.questionBankName
      } else {
        this.dataForm = {
          group: '', // 分组
          parentId: '', // 分组id
          questionBankGroupId: '', // 分组id
          questionBankName: '' // 题库名称
        }
      }
    },
    // 点击节点的响应
    _handleNodeClick (node) {
      this.dataForm.group = node.groupName
      this.dataForm.parentId = node.parentId
      this.dataForm.questionBankGroupId = node.id
      this.$refs.selectTree.blur()
    },
    submitHandle () {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.dataForm.id = undefined
          if (this.optionType === 'add') {
            this.$emit('addTopicListData', this.dataForm)
          } else if (this.optionType === 'edit') {
            this.dataForm.id = this.topicItem.questionBankId
            this.$emit('editTopicListData', this.dataForm)
          }
        }
      })
    }
  }
}
</script>
